<template>
    <div>
        <el-dialog
            title="编辑项目"
            visible
            @close="handleClose"
            class="edit-program"
            :lock-scroll="true"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="556px"
        >
            <el-form
                :model="form"
                :rules="rules"
                ref="editProgramForm"
                label-width="80px"
            >
                <el-form-item label="客户名称" prop="customerId">
                    <el-input
                        type="text"
                        v-model="form.customerName"
                        placeholder="输入客户名称"
                        disabled
                        v-if="!noCustomer"
                    ></el-input>
                    <customer-select
                        v-else
                        ref="customerSelect"
                        placeholder="输入客户关键词"
                        @customerSelected="handleCustomerSelected"
                    ></customer-select>
                </el-form-item>
                <el-form-item
                    v-if="_hasBusinessGroup"
                    label="业务分组"
                    prop="customerBusinessGroupId"
                >
                    <el-select
                        v-model="form.customerBusinessGroupId"
                        :disabled="
                            !(noCustomer || noDepartment) &&
                            !!_firstEnterBusinessGroupId
                        "
                        placeholder="请选择业务分组"
                    >
                        <el-option
                            v-for="(item, index) in aCustomerBusinessGroup"
                            :key="'customerBusinessItem_' + index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="部门名称" prop="customerDeparmentId">
                    <customer-department-select
                        ref="departmentSelect"
                        :isDisabled="!(noCustomer || noDepartment)"
                        :customerId="form.customerId"
                        :departmentId="form.customerDeparmentId"
                        :customerDepartmentName="form.customerDeparmentName"
                        placeholder="输入部门名称"
                        @update-department="updateDepartment"
                    ></customer-department-select>
                </el-form-item>

                <!-- <el-form-item label="职位类别">
                    <el-input
                        type="text"
                        v-model="form.jobSerieName"
                        placeholder="请选择区域"
                        disabled
                    ></el-input>
                </el-form-item> -->

                <el-form-item label="所在地区" prop="locations" style="margin-bottom: 0;">
                    <el-input
                        class="location-input"
                        type="text"
                        placeholder="请选择，最多选择五项"
                        readOnly="true"
                        :value="locationName"
                        :disabled="!(noCustomer || noDepartment)"
                        v-if="!(noCustomer || noDepartment)"
                    ></el-input>
                    <el-input
                        v-else
                        class="location-input"
                        type="text"
                        placeholder="请选择，最多选择五项"
                        readOnly="true"
                        @click.native="showCitySelect"
                        :value="locationName"
                    ></el-input>
                    <div class="hot-city" v-if="noCustomer || noDepartment">
                        <span v-for="(city, key) in hotCityMap" :key="key" @click="selectHotCity(city.label, city.value)">{{city.label}}</span>
                    </div>
                </el-form-item>

                <div class="hr-infos">
                    <el-form-item>
                        <span slot="label" class="self-require-balel">HR 信息</span>
                        <el-form-item class="it-cel" prop="hrName">
                            <el-input
                                type="text"
                                v-model="form.hrName"
                                placeholder="请输入 HR 姓名"
                            ></el-input>
                        </el-form-item>
                        <el-form-item class="it-cel" prop="hrTitle">
                            <el-input
                                type="text"
                                v-model="form.hrTitle"
                                maxlength="30"
                                placeholder="请输入职位名称"
                            ></el-input>
                        </el-form-item>
                        <el-form-item class="it-cel" prop="hrPhone">
                            <el-input
                                type="text"
                                v-model="form.hrPhone"
                                placeholder="请输入手机号或标准固话"
                            ></el-input>
                        </el-form-item>
                        <span class="or">或</span>
                        <el-form-item class="it-cel sel">
                            <el-input
                                type="text"
                                v-model="form.hrWeChat"
                                maxlength="20"
                                @change="handleHrWeChatChange"
                                placeholder="请输入社交号"
                            >
                            <el-select v-model="form.hrContactType" @change="hrContactTypeChange" slot="prepend" placeholder="请选择">
                                <el-option label="微信" value="1" :key="Math.random()"></el-option>
                                <el-option label="企微" value="2" :key="Math.random()"></el-option>
                                <el-option label="飞书" value="3" :key="Math.random()"></el-option>
                                <el-option label="钉钉" value="4" :key="Math.random()"></el-option>
                            </el-select>
                            </el-input>
                        </el-form-item>
                        <el-form-item class="it-cel" prop="hrEmail">
                            <el-input
                                type="text"
                                v-model="form.hrEmail"
                                placeholder="请输入联系邮箱"
                            ></el-input>
                        </el-form-item>
                    </el-form-item>

                    <div class="tip-text">
                        <i class="el-icon-view secret"></i> <span class="secret">私密</span> HR 相关信息为私密信息，仅自己可见
                    </div>
                </div>
                

                <el-form-item
                    label="项目名称"
                    prop="name"
                >
                    <el-input
                        style="width: 180px"
                        type="text"
                        v-model="form.preName"
                        :title="form.preName"
                        placeholder="输入项目名称"
                        disabled
                    ></el-input>
                    <span class="space">-</span>

                    <el-input
                        maxlength="45"
                        style="width: 170px"
                        type="text"
                        placeholder="输入项目名称"
                        v-model="form.name"
                    >
                    </el-input>
                </el-form-item>

                <!-- <el-form-item label="招聘人数" prop="headcount">
                    <el-input
                        v-model="form.headcount"
                        autocomplete="off"
                    ></el-input>
                </el-form-item> -->

                <el-form-item label="项目描述" prop="description">
                    <el-input
                        type="textarea"
                        placeholder="请输入项目描述"
                        v-model="form.description"
                        maxlength="2000"
                        rows="4"
                        show-word-limit
                    />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取消</el-button>
                <el-button type="primary" @click="handleConfirm"
                    >确定</el-button
                >
            </div>
        </el-dialog>
        <!-- 选择城市弹窗 -->
        <select-city-dialog
            ref="selectCityDialog"
            @city-selected="handleCitySelect"
        ></select-city-dialog>
    </div>
</template>

<script>
import validateMax from "../project-detail-util.js";
import cityMap from "#/js/config/provinceCity.json";
import hotCityMap from "@src/js/config/hotCity.json";
import {contactType} from "@src/js/config/dates/projectData.js";
import projectDetailService from "#/js/service/projectDetailService.js";
import SelectCityDialog from "#/component/common/dialog/select-city-dialog.vue";
import CustomerSelect from "#/component/common/customer-select.vue";
import CustomerDepartmentSelect from "#/component/common/customer-department-select.vue";
import { validateTel, validEmails, } from "#/js/util/validate.js";
export default {
    name: "edit-program",
    props: {
        projectParams: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        SelectCityDialog,
        CustomerSelect,
        CustomerDepartmentSelect,
    },
    data() {
        return {
            noCustomer: false,
            noDepartment: false,
            form: {
                preName: "", // 生成的项目前面的名称
                name: "", // 项目名称
                customerBusinessGroupId: "",
                // headcount: 0, // 招聘人数
                customerName: "", // 客户名称
                customerId: "",
                customerDeparmentName: "", // 部门名称
                customerDeparmentId: "",
                // jobSerieName: '', // 职位类别
                locationIds: "", // 所在地区
                hrName: "", // HR姓名
                hrPhone: '',
                hrWeChat: '',
                hrContactType: "1",
                hrTitle: '',
                hrEmail: '',

                description: "", // 项目描述
                locations: [],
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入项目名称",
                        trigger: "blur",
                    },
                ],
                customerBusinessGroupId: [
                    {
                        required: true,
                        message: "请选择业务分组",
                        trigger: "blur",
                    },
                ],
                // headcount: [
                //     {
                //         required: true,
                //         message: "请输入招聘人数",
                //         trigger: "blur",
                //     },
                //     { validator: validateMax, trigger: "blur" },
                // ],
                customerId: [
                    { required: true, message: "请选择客户", trigger: "blur" },
                ],
                // customerDeparmentId: [
                //     { required: true, message: "请选择部门", trigger: "blur" },
                // ],
                customerDeparmentId: [
                    {
                        required: true,
                        validator: (rules, value, callback) => {
                            if (
                                value == 0 ||
                                value == "" ||
                                value == undefined ||
                                value == null
                            ) {
                                callback(new Error("请选择部门"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                        // trigger: "change",
                    },
                ],
                locations: [
                    {
                        required: true,
                        message: "请选择所在地区",
                        trigger: "change",
                    },
                    {
                        validator: (rules, value, callback) => {
                            if (value && value.length > 5) {
                                callback(new Error("最多选择五项地区"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "change",
                    },
                ],
                hrName: [
                    {
                        required: true,
                        message: "请输入HR姓名",
                        trigger: "blur",
                    },
                ],
                hrPhone: [
                    // {
                    //     required: true,
                    //     message: "手机和微信号必填一个",
                    //     trigger: "blur",
                    // },
                    { validator: this.validateMobile, trigger: ["blur", "change"] },
                ],
                // hrWeChat: [
                //     { validator: this.validateMobile, trigger: ["blur", "change"] },
                // ],
                hrTitle: [
                    {
                        required: true,
                        message: "请输入HR职位名称",
                        trigger: "blur",
                    },
                ],
                hrEmail: [
                    { validator: this.validateEmail, trigger: ["blur", "change"] },
                ],
                description: [
                    {
                        required: true,
                        message: "请输入项目描述",
                        trigger: "blur",
                    },
                    {
                        max: 2000,
                        message: "项目描述长度应在20个字符以内",
                        trigger: "blur",
                    },
                ],
            },

            locationName: "",
            hotCityMap: hotCityMap,
            aCustomerBusinessGroup: [], // 业务分组
            _firstEnterBusinessGroupId: "",
            _firstEnterCustomerId: "",
        };
    },
    computed: {
        // 若部门被删除则 除职位类别和客户，其他可编辑。
        // 若客户被删除则 除职位类别，其他都可编辑。
        // noCustomer() {
        //     if(this.$route.query.hasOwnProperty('nocustomer')){
        //         return this.form.customerId == "00000000-0000-0000-0000-000000000000";
        //     } else {
        //         return this.form.customerId == "00000000-0000-0000-0000-000000000000";
        //     }
        // },
        // noDepartment() {
        //     if(this.$route.query.hasOwnProperty('nodepartment')) {
        //         return this.form.customerDeparmentName == null;
        //     } else {
        //         return this.form.customerDeparmentName == null;
        //     }
        // },
        _hasBusinessGroup() {
            if (
                this._firstEnterCustomerId ==
                "00000000-0000-0000-0000-000000000000"
            ) {
                this.form.customerBusinessGroupId = "";
                return this.aCustomerBusinessGroup.length > 0;
            } else {
                return this.form.hasBusinessGroup;
            }
        },
    },
    created() {
        this.getProjectDetailForm();
    },
    mounted() {
        if (this.$route.query.hasOwnProperty("nocustomer")) {
            this.noCustomer =
                this.form.customerId == "00000000-0000-0000-0000-000000000000"
                    ? true
                    : false;
        } else {
            this.noCustomer =
                this.form.customerId == "00000000-0000-0000-0000-000000000000"
                    ? true
                    : false;
        }

        if (this.$route.query.hasOwnProperty("nodepartment")) {
            this.noDepartment =
                this.form.customerDeparmentName == null ? true : false;
        } else {
            this.noDepartment =
                this.form.customerDeparmentName == null ? true : false;
        }
    },
    methods: {
        hrContactTypeChange(val) {
            this.$forceUpdate();
        },
        handleHrWeChatChange() {
            this.$refs.editProgramForm.validateField(
                "hrPhone",
                (valid) => {}
            );
        },
        validateMobile(rule, value, callback) {
            // let regexMobileExternal = /^(\+|00)\d{8,15}$/,
            let regexMobileExternal = /^(\+\d{2,4}-)?\d{2,4}-\d{7,8}$/,
                regexMobileInternal = /^(\+\d{2,4}-)?(1[3-9])\d{9}$/;
            let hrPhone = this.form.hrPhone,
                hrWeChat = this.form.hrWeChat;

            if((String(hrPhone).trim() == '' || hrPhone == null )&& (String(hrWeChat).trim() == '' || hrWeChat == null)) {
                callback(new Error('手机固话或社交号必须提供一个'));
            }

            if(!!hrPhone) {
                // let arr = hrPhone.match(regexMobileExternal) || hrPhone.match(regexMobileInternal);
                const regRes = regexMobileExternal.test(hrPhone) || regexMobileInternal.test(hrPhone);
                if(!regRes) {
                    callback(new Error('请输入正确号码，有区号用横杠-隔开'));
                } else {
                    callback();
                }
            }
            if(!!hrWeChat) {
                callback();
            }
        },
        validateEmail(rule, value, callback) {
            let emailReg = /^[A-Za-z0-9\u4e00-\u9fa5\._-]+@[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(?:\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/;
            if(value && value.length > 50) {
                callback(new Error('邮箱最长支持50个字符'));
            } else if(value && !emailReg.test(value)) {
                callback(new Error('请输入正确的电子邮箱'));
            } else {
                callback();
            }
        },
        // 点击编辑按钮，获取项目的详情信息
        getProjectDetailForm() {
            // console.log(this.projectParams);
            if (
                this.projectParams &&
                JSON.stringify(this.projectParams.length) != "{}"
            ) {
                this.form =
                    {
                        ...this.projectParams,
                        locations: this.projectParams.locationIds?.split("."),
                    } || {};
                // console.log(this.form);

                const currentCitys = this.form.locations.map((item) => {
                    return cityMap[item];
                });
                this.locationName = currentCitys.join(",");
                // console.log(this.locationName);

                let $index = this.form.name.lastIndexOf("$#$");
                // 没有找到$#$的时候，不是新创建的项目数据，eg：项目名称为 A1（就是自己自定义的名字）
                if ($index == -1) {
                    // 获取preName,方法1：部门被删除时，项目名称部门部分会为null
                    // 命名时地区按首字母顺序排序
                    // console.log(this.locationName)
                    let locationName = this.locationName
                        .split(",")
                        .sort((param1, param2) => {
                            return param1.localeCompare(param2, "zh");
                        })
                        //   .reverse()
                        .join(",");
                    // console.log(locationName);
                    this.form.preName =
                        this.form.customerDeparmentName +
                        "-" +
                        `${locationName}`;
                    // console.log(this.form.preName);
                } else {
                    // 获取preName,方法2：避免部门被删除时，项目名称部门部分为null
                    // this.form.name = this.form.name.replace("$#$", "-");
                    // 从后找"-"的下标
                    // let index = this.form.name.lastIndexOf("-");
                    // console.log(index);
                    // this.form.preName = this.form.name.substring(0, index);
                    // this.form.name = this.form.name.substring(index + 1);

                    // this.form.preName = this.form.name.substring(0, $index);
                    this.form.preName = this.form.name.substring(0, $index).replace("$#$", "-");
                    this.form.name = this.form.name.substring($index + 3);
                }
                if(this.form.hrWeChat && this.form.hrWeChat.indexOf("|") > -1) {
                    var arr = this.form.hrWeChat.split("|");
                    this.form.hrContactType = arr[0];
                    this.form.hrWeChat = arr[1];
                } else {
                    this.form.hrContactType = '1';
                }
                // console.log(this.form.preName)
                // console.log(this.form.name)
            }
            if (this.form.hasBusinessGroup) {
                this.getProjectBusinessGroup(this.form.customerId);
            }
            this._firstEnterBusinessGroupId = this.form.customerBusinessGroupId;
            this._firstEnterCustomerId = this.form.customerId;
        },
        // 关闭编辑项目弹窗
        handleClose() {
            this.aCustomerBusinessGroup = [];
            this.$emit("edit-program-finish");
        },
        getHrWeChat(oldStr, type) {
            // {沟通渠道}|{对应号码}
            if(!oldStr) return '';
            var oldArr = oldStr.split("|");
            if(oldArr.length > 1) {
                return `${type}|${oldArr[1]}`
            } else {
                return `${type}|${oldStr}`
            }
        },
        // 点击确定按钮，提交编辑项目信息
        handleConfirm() {
            this.$refs["editProgramForm"].validate((valid) => {
                if (valid) {
                    const _locations =
                        this.form.locations.length > 0
                            ? this.form.locations
                            : this.form.locationIds.split(".");
                    let params = {
                        ...this.form,
                        hrWeChat: this.getHrWeChat(this.form.hrWeChat, this.form.hrContactType||'1'),
                        // headcount: +this.form.headcount,
                        locations: _locations,
                    };
                    
                    // console.log(params);
                    // 传给后端的name = 前面部分内容$#$后面部分内容
                    params.name = params.preName + "$#$" + this.form.name;
                    const loading = this.$loading({
                        lock: true,
                        text: "页面加载中~",
                        background: "rgba(255, 255, 255, 0.7)",
                    });

                    projectDetailService
                        .createOrUpdateProject(params)
                        .then((res) => {
                            shortTips(`项目编辑成功！`);
                            this.$emit("edit-program-finish", true);
                        })
                        .finally(() => {
                            loading.close();
                        });
                }
            });
        },

        // 选择客户
        handleCustomerSelected(customer) {
            this.form.customerId = customer.customerId;
            this.form.customerName = customer.customerName;
            if (!customer.customerId) {
                this.form.customerDeparmentId = "";
                this.form.customerDeparmentName = "";

                // 清空业务分组数据
                this.form.customerBusinessGroupId = "";
                this.aCustomerBusinessGroup = [];
            }
            this.getProjectBusinessGroup(customer.customerId);

            this.$refs.departmentSelect.reset();
            this.generateProjectName();

            this.$refs.editProgramForm.validateField(
                "customerId",
                (valid) => {}
            );
        },
        // 更新部门
        updateDepartment(val) {
            this.$set(this.form, "customerDeparmentId", val.id);
            this.$set(this.form, "customerDeparmentName", val.departmentName);
            // console.log(this.$refs);
            // console.log(this.$refs.editProgramForm.validateField);
            this.$refs.editProgramForm.validateField(
                "customerDeparmentId",
                (valid) => {}
            );
            this.generateProjectName();
        },
        // 生成项目前面部分名称
        generateProjectName() {
            // if (this.form.customerDeparmentName && this.locationName) {
            //     this.form.preName = `${this.form.customerDeparmentName}-${this.locationName}`;
            // } else {
            //     this.form.preName = "";
            // }
            if (
                this.form.customerDeparmentId &&
                this.form.locations.length > 0
            ) {
                // console.log(this.$refs.departmentSelect);
                let departmentName = this.$refs.departmentSelect.selectText;
                departmentName = departmentName.slice(
                    departmentName.lastIndexOf("-") + 1,
                    departmentName.length
                ).replace(/\n/g,"").replace(/\r/g,"");
                // 命名时地区按首字母顺序排序
                let locationName = this.locationName
                    .split(",")
                    .sort((param1, param2) => {
                        return param1.localeCompare(param2, "zh");
                    })
                    //   .reverse()
                    .join(",");
                // console.log(locationName);
                this.form.preName = `${departmentName}-${locationName}`;
            } else {
                this.form.preName = "";
            }
        },
        selectHotCity(name, code) {
            if (this.form.locations.length == 5) {
                shortTips("最多选择五项地区！");
                return false;
            }
            if (this.form.locations.indexOf(code) > -1) {
                return false;
            }
            this.form.locations.push(code);
            switch (name) {
                case "北":
                    name = "北京";
                    break;
                case "上":
                    name = "上海";
                    break;
                case "广":
                    name = "广州";
                    break;
                case "深":
                    name = "深圳";
                    break;
                default:
                    break;
            }

            if (this.locationName) {
                this.locationName = `${this.locationName},${name}`;
            } else {
                this.locationName = name;
            }
            // this.$refs.selectCityDialog.selectCityOutside(code);
            // this.handleCitySelect();

            this.generateProjectName();
        },
        showCitySelect() {
            this.$refs.selectCityDialog.show();
            this.$nextTick(() => {
                if (this.$refs.selectCityDialog.$refs.provinceCity) {
                    this.$refs.selectCityDialog.$refs.provinceCity.clearSelected();
                    this.form.locations.forEach((code) => {
                        this.$refs.selectCityDialog.$refs.provinceCity.selectCityOutside(
                            code
                        );
                    });
                }
            });
        },
        handleCitySelect() {
            let selected = this.$refs.selectCityDialog.selectedCity;
            console.log(selected);
            let target = [];
            let targetNames = [];
            selected.forEach((item) => {
                target.push(item.id);
                targetNames.push(item.text);
            });
            this.form.locations = target;
            this.locationName = targetNames.join(",");

            this.generateProjectName();
        },
        /**
         * 获取业务分组数据
         */
        getProjectBusinessGroup(customerId) {
            if (!customerId) return;
            projectDetailService
                .getProjectBusinessGroup(customerId)
                .then((res) => {
                    this.aCustomerBusinessGroup = res || [];
                });
        },
    },
};
</script>

<style lang="scss" scope>
.edit-program {
    .el-dialog__body,
    .el-dialog__footer {
        padding-left: 49px;
        padding-right: 49px;
    }
    .el-form-item{
        padding-right: 10px;
    }
}
.hr-infos {
    border: 1px dashed #eee;
    padding: 0 0 10px 0;
    margin-bottom: 10px;
    margin-top: 10px;
    background: #fcfcfc;
    >.el-form-item{
        margin-bottom: 2px;
        padding: 0 5px 0;
        >.el-form-item__label{
            width: 100%;
            text-align: left;
        }
        >.el-form-item__content{
            width: 100%;
            margin-left: 0!important;
        }
    }
    .el-form-item__content{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        &::before{
            display: none;
        }
        .it-cel{
            // width: 46%;
            // min-width: 46%;
            // max-width: 46%;
            width: 205px;
            margin-bottom: 18px;
        }
        .or{
            font-size: 14px;
            color: #666;
        }
        >.el-form-item{
            padding-right: 0;
        }
        .sel{
            .el-input-group__prepend{
                padding: 0;
                >.el-select{
                    margin: 0;
                    width: 90px;
                }
            }
        }
    }
    .self-require-balel{
        font-size: 14px;
        color: #666;
        &::before{
            content: '*';
            color: #F56C6C;
            margin-right: 4px;
        }
    }
    
    .tip-text{
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        color: #777;
        text-align: center;
        .secret{
            color: #F56C6C;
        }
    }
}
.el-select {
    width: 100%;
}
.hot-city {
    span {
        display: inline-block;
        padding: 0 4px;
        color: $primary;
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }
}
.location-input .el-input__inner {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // width: 185px;
}
.space {
    margin: 0 3px;
}
</style>