var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "edit-program",
          attrs: {
            title: "编辑项目",
            visible: "",
            "lock-scroll": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "556px",
          },
          on: { close: _vm.handleClose },
        },
        [
          _c(
            "el-form",
            {
              ref: "editProgramForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户名称", prop: "customerId" } },
                [
                  !_vm.noCustomer
                    ? _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "输入客户名称",
                          disabled: "",
                        },
                        model: {
                          value: _vm.form.customerName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "customerName", $$v)
                          },
                          expression: "form.customerName",
                        },
                      })
                    : _c("customer-select", {
                        ref: "customerSelect",
                        attrs: { placeholder: "输入客户关键词" },
                        on: { customerSelected: _vm.handleCustomerSelected },
                      }),
                ],
                1
              ),
              _vm._hasBusinessGroup
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "业务分组",
                        prop: "customerBusinessGroupId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled:
                              !(_vm.noCustomer || _vm.noDepartment) &&
                              !!_vm._firstEnterBusinessGroupId,
                            placeholder: "请选择业务分组",
                          },
                          model: {
                            value: _vm.form.customerBusinessGroupId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "customerBusinessGroupId", $$v)
                            },
                            expression: "form.customerBusinessGroupId",
                          },
                        },
                        _vm._l(
                          _vm.aCustomerBusinessGroup,
                          function (item, index) {
                            return _c("el-option", {
                              key: "customerBusinessItem_" + index,
                              attrs: { label: item.name, value: item.id },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "部门名称", prop: "customerDeparmentId" } },
                [
                  _c("customer-department-select", {
                    ref: "departmentSelect",
                    attrs: {
                      isDisabled: !(_vm.noCustomer || _vm.noDepartment),
                      customerId: _vm.form.customerId,
                      departmentId: _vm.form.customerDeparmentId,
                      customerDepartmentName: _vm.form.customerDeparmentName,
                      placeholder: "输入部门名称",
                    },
                    on: { "update-department": _vm.updateDepartment },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { label: "所在地区", prop: "locations" },
                },
                [
                  !(_vm.noCustomer || _vm.noDepartment)
                    ? _c("el-input", {
                        staticClass: "location-input",
                        attrs: {
                          type: "text",
                          placeholder: "请选择，最多选择五项",
                          readOnly: "true",
                          value: _vm.locationName,
                          disabled: !(_vm.noCustomer || _vm.noDepartment),
                        },
                      })
                    : _c("el-input", {
                        staticClass: "location-input",
                        attrs: {
                          type: "text",
                          placeholder: "请选择，最多选择五项",
                          readOnly: "true",
                          value: _vm.locationName,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.showCitySelect.apply(null, arguments)
                          },
                        },
                      }),
                  _vm.noCustomer || _vm.noDepartment
                    ? _c(
                        "div",
                        { staticClass: "hot-city" },
                        _vm._l(_vm.hotCityMap, function (city, key) {
                          return _c(
                            "span",
                            {
                              key: key,
                              on: {
                                click: function ($event) {
                                  return _vm.selectHotCity(
                                    city.label,
                                    city.value
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(city.label))]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "hr-infos" },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "span",
                        {
                          staticClass: "self-require-balel",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [_vm._v("HR 信息")]
                      ),
                      _c(
                        "el-form-item",
                        { staticClass: "it-cel", attrs: { prop: "hrName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入 HR 姓名",
                            },
                            model: {
                              value: _vm.form.hrName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "hrName", $$v)
                              },
                              expression: "form.hrName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { staticClass: "it-cel", attrs: { prop: "hrTitle" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              maxlength: "30",
                              placeholder: "请输入职位名称",
                            },
                            model: {
                              value: _vm.form.hrTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "hrTitle", $$v)
                              },
                              expression: "form.hrTitle",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { staticClass: "it-cel", attrs: { prop: "hrPhone" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入手机号或标准固话",
                            },
                            model: {
                              value: _vm.form.hrPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "hrPhone", $$v)
                              },
                              expression: "form.hrPhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "or" }, [_vm._v("或")]),
                      _c(
                        "el-form-item",
                        { staticClass: "it-cel sel" },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                type: "text",
                                maxlength: "20",
                                placeholder: "请输入社交号",
                              },
                              on: { change: _vm.handleHrWeChatChange },
                              model: {
                                value: _vm.form.hrWeChat,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "hrWeChat", $$v)
                                },
                                expression: "form.hrWeChat",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    slot: "prepend",
                                    placeholder: "请选择",
                                  },
                                  on: { change: _vm.hrContactTypeChange },
                                  slot: "prepend",
                                  model: {
                                    value: _vm.form.hrContactType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "hrContactType", $$v)
                                    },
                                    expression: "form.hrContactType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: Math.random(),
                                    attrs: { label: "微信", value: "1" },
                                  }),
                                  _c("el-option", {
                                    key: Math.random(),
                                    attrs: { label: "企微", value: "2" },
                                  }),
                                  _c("el-option", {
                                    key: Math.random(),
                                    attrs: { label: "飞书", value: "3" },
                                  }),
                                  _c("el-option", {
                                    key: Math.random(),
                                    attrs: { label: "钉钉", value: "4" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { staticClass: "it-cel", attrs: { prop: "hrEmail" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入联系邮箱",
                            },
                            model: {
                              value: _vm.form.hrEmail,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "hrEmail", $$v)
                              },
                              expression: "form.hrEmail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "tip-text" }, [
                    _c("i", { staticClass: "el-icon-view secret" }),
                    _c("span", { staticClass: "secret" }, [_vm._v("私密")]),
                    _vm._v(
                      " HR 相关信息为私密信息，仅自己可见\n                "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "项目名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: {
                      type: "text",
                      title: _vm.form.preName,
                      placeholder: "输入项目名称",
                      disabled: "",
                    },
                    model: {
                      value: _vm.form.preName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "preName", $$v)
                      },
                      expression: "form.preName",
                    },
                  }),
                  _c("span", { staticClass: "space" }, [_vm._v("-")]),
                  _c("el-input", {
                    staticStyle: { width: "170px" },
                    attrs: {
                      maxlength: "45",
                      type: "text",
                      placeholder: "输入项目名称",
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "项目描述", prop: "description" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入项目描述",
                      maxlength: "2000",
                      rows: "4",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("select-city-dialog", {
        ref: "selectCityDialog",
        on: { "city-selected": _vm.handleCitySelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }